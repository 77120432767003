import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="navbar">
      <Link to={`/`}>Home</Link>
      <Link to={`/page1.html`}>Page 1</Link>
      <Link to={`/page2.html`}>Page 2</Link>
      <Link to={`/page3.html`}>Page 3</Link>
      <Link to={`/page4.html`}>Page 4</Link>
      {/* <Link to={`/login.html`}>Login</Link> */}
      <Link to={`/list_1`}>List 1</Link>
      <Link to={`/list_2`}>List 2</Link>
      <Link to={`/list_3`}>List 3</Link>
      <Link to={`/product`}>Detail Page</Link>
      <Link to={`/purchase`}>Purchase Page</Link>
    </div>
  );
}

export default Navbar;
