import React from "react";
import { Link } from "react-router-dom";
import { pushDataLayerEvent } from "../utils/dataLayer";

function Content() {
  return (
    <div className="row">
      <div className="side">
        <h2>About Me</h2>
        <h5>Photo of me:</h5>

        <div className="fakeimg" style={{ height: "200px" }}>
          Image
        </div>

        <p>Some text about me in culpa qui officia deserunt mollit anim..</p>
        <h3>More Text</h3>
        <p>Lorem ipsum dolor sit ame.</p>

        <Link to="/products" style={{ textDecoration: "none", outline: "none", color: "inherit" }} onClick={() => pushDataLayerEvent("promotion_click", "ecommerce", "promotion_click", "banner_1")}>
          <div className="fakeimg" style={{ height: "60px" }}>
            Internal Promotion Banner 1
          </div>
        </Link>

        <br />

        <Link to="/products" style={{ textDecoration: "none", outline: "none", color: "inherit" }} onClick={() => pushDataLayerEvent("promotion_click", "ecommerce", "promotion_click", "banner_2")}>
          <div className="fakeimg" style={{ height: "60px" }}>
            Internal Promotion Banner 2
          </div>
        </Link>

        <br />

        <Link to="/products" style={{ textDecoration: "none", outline: "none", color: "inherit" }} onClick={() => pushDataLayerEvent("promotion_click", "ecommerce", "promotion_click", "banner_3")}>
          <div className="fakeimg" style={{ height: "60px" }}>
            Internal Promotion Banner 3
          </div>
        </Link>
      </div>

      <div className="main">
        <h2>TITLE HEADING</h2>
        <h5>Title description, Dec 7, 2017</h5>

        <button onClick={() => pushDataLayerEvent("event")}>Event test 1</button>
        <button onClick={() => pushDataLayerEvent("event")}>Event test 2</button>
        <button onClick={() => pushDataLayerEvent("event")}>Event test 3</button>

        <div className="fakeimg" style={{ height: "200px" }}>
          Image
        </div>

        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/QdXvahdWe-c"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen></iframe>

        <p>Some text..</p>
        <p>Sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>

        <br />

        <h2>TITLE HEADING</h2>
        <h5>Title description, Sep 2, 2017</h5>

        <div className="fakeimg" style={{ height: "200px" }}>
          Image
        </div>

        <p>Some text..</p>
        <p>Sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
      </div>
    </div>
  );
}

export default Content;
