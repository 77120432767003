import "../css/App.css";
import Login from "../pages/Login";
import Page from "../pages/Page";
import Home from "../pages/Home";
import ProductsList from "../pages/ProductsList";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProductPage from "../pages/ProductPage";
import PurchasePage from "../pages/PurchasePage";
import { list_1, list_2, list_3 } from "../data/lists";
import { Provider } from "../context/CartContext";

function App() {
  return (
    <Provider>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="page1.html" element={<Page />} />
            <Route path="page2.html" element={<Page />} />
            <Route path="page3.html" element={<Page />} />
            <Route path="page4.html" element={<Page />} />
            <Route path="login.html" element={<Login />} />
            <Route
              path="list_1"
              element={<ProductsList listName="list_1" products={list_1} />}
            />
            <Route
              path="list_2"
              element={<ProductsList listName="list_2" products={list_2} />}
            />
            <Route
              path="list_3"
              element={<ProductsList listName="list_3" products={list_3} />}
            />
            <Route path="product" element={<ProductPage />} />
            <Route path="purchase" element={<PurchasePage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
