import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { pushDataLayerEvent } from "../utils/dataLayer";
import { useEffect } from "react";

function ProductsList(props) {

  useEffect(() => {
    pushDataLayerEvent("list_view", "ecommerce", "list_view", props.listName, props.products);
  }, [props]);

  return (
    <>
      <Header />
      <Navbar />
      <ul>
        {props.products.map((product) => (
          <li key={product.id}>
            <Link to={`/product`} state={{ product: product }} onClick={ () => pushDataLayerEvent("product_click", "ecommerce", "product_click", product.id, [product], props.listName) }>
              {product.name}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}

export default ProductsList;
