import React from 'react'

function Header() {
    return (
			<div className='header'>
				<h1>My Website</h1>
				<p>A website created by me.</p>
			</div>
		);
}

export default Header
