import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { pushDataLayerEvent } from "../utils/dataLayer";
import { v1 as uuidv1 } from "uuid";
import { useEffect, useMemo } from "react";

function PurchasePage() {
  const products = useMemo(
    () => [
      {
        name: "Product 4",
        id: "product_4",
        price: "0.00",
        position: "4",
        list: "list_1",
      },
      {
        name: "Product 3",
        id: "product_3",
        price: "0.00",
        position: "3",
        list: "list_2",
      },
    ],
    []
  );

  useEffect(() => {
    pushDataLayerEvent("purchase", "ecommerce", "purchase", uuidv1(), products);
  }, [products]);

  return (
    <>
      <Header />
      <Navbar />
      <div>
        <h1>Purchase Thank You Page</h1>
      </div>
    </>
  );
}

export default PurchasePage;
