export var list_1 = [
  {
    name: "Product 1",
    id: "product_1",
    position: "1",
    list: "list_1",
  },
  {
    name: "Product 2",
    id: "product_2",
    position: "2",
    list: "list_1",
  },
  {
    name: "Product 3",
    id: "product_3",
    position: "3",
    list: "list_1",
  },
  {
    name: "Product 4",
    id: "product_4",
    position: "4",
    list: "list_1",
  },
];

export var list_2 = [
  {
    name: "Product 1",
    id: "product_1",
    position: "1",
    list: "list_2",
  },
  {
    name: "Product 2",
    id: "product_2",
    position: "2",
    list: "list_2",
  },
  {
    name: "Product 3",
    id: "product_3",
    position: "3",
    list: "list_2",
  },
  {
    name: "Product 4",
    id: "product_4",
    position: "4",
    list: "list_2",
  },
];

export var list_3 = [
  {
    name: "Product 1",
    id: "product_1",
    position: "1",
    list: "list_3",
  },
  {
    name: "Product 2",
    id: "product_2",
    position: "2",
    list: "list_3",
  },
  {
    name: "Product 3",
    id: "product_3",
    position: "3",
    list: "list_3",
  },
  {
    name: "Product 4",
    id: "product_4",
    position: "4",
    list: "list_3",
  },
];
