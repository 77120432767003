import React from "react";
import Header from "../components/Header.js";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Content from "../components/Content";

function Page() {
  return (
    <>
      <Header></Header>
      <Navbar></Navbar>
      <Content></Content>
      <Footer></Footer>
    </>
  );
}

export default Page;
