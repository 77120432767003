import React, { useContext } from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { pushDataLayerEvent } from "../utils/dataLayer";
import { v1 as uuidv1 } from "uuid";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { cartContext } from "../context/CartContext";

function ProductPage() {
  const location = useLocation();
  const { product } = location.state;
  const { dispatch } = useContext(cartContext);

  useEffect(() => {
    pushDataLayerEvent("detail_view", "ecommerce", "detail_view", product.id, [product]);
  }, [product]);

  return (
    <>
      <Header />
      <Navbar />
      <div>
        <h1>{product.name} Detail Page</h1>
        <button
          onClick={() => {
            pushDataLayerEvent("add_to_cart", "ecommerce", "add_to_cart", product.id, [product]);
            dispatch({type: "ADD_TO_CART", payload: product})
          }}
        >
          Add To Cart
        </button>
        
        <button
          onClick={() =>
            pushDataLayerEvent("purchase", "ecommerce", "purchase", uuidv1(), [
              product,
            ])
          }
        >
          Purchase
        </button>
      </div>
    </>
  );
}

export default ProductPage;
