import React from "react";
import Header from "../components/Header.js";
import Navbar from "../components/Navbar.js";

function Login() {
  return (
    <>
      <Header></Header>
      <Navbar></Navbar>
    </>
  );
}

export default Login;
