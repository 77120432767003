export function pushDataLayerEvent(eventName, eventCategory, eventAction, eventLabel, products, listName) {
  var obj = {
    event: eventName,
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel,
  };

  switch (eventName) {
    case "list_view":
      obj["ecommerce"] = {
        impressions: products,
      };
      break;
    case "product_click":
      obj["ecommerce"] = {
        click: {
          actionField: { list: listName },
          products: products,
        },
      };
      break;
    case "detail_view":
      obj["ecommerce"] = {
        detail: {
          products: products.map((pr) => ({ id: pr.id, name: pr.name })),
        },
      };
      break;
    case "add_to_cart":
      obj["ecommerce"] = {
        add: {
          products: products.map((pr) => ({
            id: pr.id,
            name: pr.name,
            quantity: 1,
          })),
        },
      };
      break;
    case "purchase":
      obj["ecommerce"] = {
        transaction_id: "T_12345",
        value: "0.00",
        tax: "0.00",
        shipping: "0.00",
        currency: "USD",
        coupon: "SUMMER_SALE",
        items: products.map((pr) => ({
          item_id: pr.id,
          item_name: pr.name,
          price: pr.price,
          quantity: 1,
        })),
      };
      break;
    case "promotion_click":
      obj["ecommerce"] = {
        promoClick: {
          promotions: [
            {
              id: eventLabel,
              name: eventLabel,
              creative: eventLabel + "_creative",
              position: eventLabel + "_position",
            },
          ],
        },
      };
      break;
    default:
  }

  window.dataLayer.push(obj);
}
