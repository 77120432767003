import React from "react";

function Footer() {
	return (
		<div className='footer'>
			<h2>Footer</h2>
		</div>
	);
}

export default Footer;
